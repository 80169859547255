// nuxt-graphql-client は useGql と useAsyncGql(useAsyncDataを使うuseGqlのwrapper) を提供しているが、
// cdp-dashboard では cdp-rails のエラーデザインによってクエリによらないエラーの通信エラーハンドリングを共通化できる
// このモジュールではこの共通したエラーハンドリングを行うwrapperである useQuery, useAsyncQuery を提供している
// なお、このモジュールの関数は通信エラーのときには例外を投げず、単にnullを返します (handleQueryIndependentErrorのコメントを参照)

import type { GqlError as NuxtGraphqlClientError } from 'nuxt-graphql-client'
import { watch, type Ref } from 'vue'

import { useCsrfToken } from './useCsrfToken'
import { useToasts } from './useToasts'

import { useRequestURL, type AsyncDataOptions } from '#app'
import { useAsyncGql, useGql, useGqlHeaders } from '#imports'
import type { GqlOperations, GqlResult, GqlVariables } from '~/utils/graphql-helpers'

// nuxt-graphql-clientの型名が最悪だが、nuxt-graphql-clientのエラー型 GqlError のフィールド gqlErrors に
// graphql-requestのエラー型 GraphQLError[] が入っている
// graphql-requestにcdp-dashboardは直接依存しないが、部分的に構造に依存するためここでエイリアスを作る
type GqlErrors = NuxtGraphqlClientError['gqlErrors']

export function useHandleQueryIndependentError() {
  const toasts = useToasts()
  const url = useRequestURL()

  return (error: unknown) => {
    if (!error) return

    let gqlErrors = (error as { gqlErrors?: GqlErrors }).gqlErrors

    // XXX: おそらく Nuxt3 useAsyncData のバグを踏んでいるか nuxt-graphql-client のバグを踏んでいるかしているが
    // error.value が refetch 時に H3Error? という例外で一段階くるまれているケースがある
    // 細かい再現条件などは不明だが型と一致しないので cast して回避している... (gqlErrorsという固有なフィールドを参照するので副作用は小さいと考える)
    gqlErrors ||= (error as { cause?: { gqlErrors?: GqlErrors } }).cause?.gqlErrors

    if (gqlErrors) {
      const storesCustomerError = gqlErrors.find(e => e.extensions && e.extensions.stores_customer_error_code)

      if (storesCustomerError) {
        switch (storesCustomerError.extensions.stores_customer_error_code) {
          // 認証エラーの場合はログインを行うページにリダイレクトさせる
          case 401: {
            window.location.href = `/auth/stores_id/start${url.pathname}?${url.search}`
            return
          }
          default:
            break
        }
      }

      // ここに流れるエラーは
      // 1. クエリ固有のエラーでない (cdp-railsのエラー設計に基づく。そのためcaller側に返してもcallerにとって有用でない)
      // 2. 未認証の場合などの固有のハンドリングができるエラーでない (その場合は上でハンドリングしているため)
      // ため、共通のトースト表示といったエラーハンドリングをして差し支えない
      toasts?.error(
        '通信エラーが発生しました。しばらく待ってから再度お試しください',
      )
    }
  }
}

async function useCsrfTokenOnQuery() {
  const csrfToken = await useCsrfToken()
  watch(csrfToken, value => value && useGqlHeaders({ 'X-CSRF-Token': value }), { immediate: true })

  // NOTE: 実装上は、csrf_tokenの取得に失敗した場合もこの関数は例外を投げたりはしないので後続のリクエストが実行されてしまう
  // ただcsrf_tokenの取得に失敗するケースはサーバ自体がダウンしているか通信エラーが発生しているかのみと考えると
  // ここでリクエストが走ってもcsrf verification failedが大量に発生するということは無いと考えられる
}

export async function useQuery() {
  const handleQueryIndependentError = useHandleQueryIndependentError()
  await useCsrfTokenOnQuery()
  const GqlInstance = useGql()

  return async <Op extends GqlOperations>(op: Op, variables?: GqlVariables<Op>) => {
    try {
      // NOTE: csrf_tokenの取得に失敗した場合もリクエストが走ってしまう
      return await GqlInstance(op, variables || {})
    }
    catch (error: unknown) {
      handleQueryIndependentError(error)
      return null
    }
  }
}

type Refable<T> = { [K in keyof T]: Ref<T[K]> | T[K] }

// useQuery の useAsyncData する版
// (内部的には、nuxt-graphql-client自身もuseGqlに対するwrapper useAsyncGqlを提供しているので、代わりにそれを使用している)
export async function useAsyncQuery<Op extends GqlOperations>(
  op: Op,
  variables?: Refable<GqlVariables<Op>>,
  options?: AsyncDataOptions<GqlResult<Op>>,
) {
  const handleQueryIndependentError = useHandleQueryIndependentError()
  await useCsrfTokenOnQuery()
  const { data: _data, error, status, refresh } = await useAsyncGql(op, variables, options)

  if (error.value) {
    handleQueryIndependentError(error.value)
  }

  // XXX: useAsyncGql の型が lazy: true を考慮したものになっていないため、手動で型を広げている
  // こちらは通信エラーの場合に発生する。
  // エラーハンドリング自体は handleQueryIndependentError によって行われているためページを無難に表示することが求められる
  const data: Ref<typeof _data.value | null> = _data

  return { data, status, refresh }
}

// useAsyncQuery の Lazy 版
export async function useLazyAsyncQuery<Op extends GqlOperations>(
  op: Op,
  variables?: Refable<GqlVariables<Op>>,
  options?: AsyncDataOptions<GqlResult<Op>>,
) {
  const handleQueryIndependentError = useHandleQueryIndependentError()
  useCsrfTokenOnQuery()

  const { data: _data, error, status } = await useAsyncGql(op, variables, {
    ...options,
    lazy: true,
  })

  watch(error, (err) => {
    if (err) {
      handleQueryIndependentError(err)
    }
  })

  // XXX: useAsyncGql の型が lazy: true を考慮したものになっていないため、手動で型を広げている
  // こちらは通信中にもnullとなる。nullの場合はページがロード中であることがわかるように表示することが求められる
  const data: Ref<typeof _data.value | null> = _data

  return { data, status }
}
