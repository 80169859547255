// CSRF Tokenを取得する

import { computed } from 'vue'

import { useFetch } from '#app'

// NOTE: この関数自体はエラー発生時に副作用を起こさず、nullを返す場合がある
export async function useCsrfToken() {
  // FIXME: vitestの文脈で相対パスを使うとmswがうまく働かない...
  // vitestがnode環境で実行されているが、useFetchが ssr: false を考慮しない? ためか
  // 「相対パス」の概念がないnode環境でinvalidなfetchが実行されエラーとなっている気がする
  const { data } = await useFetch(`${window.location.origin}/api/csrf_token`)

  return computed(() => (data.value as { csrf_token: string } | undefined)?.csrf_token || null)
}
